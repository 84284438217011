export default (urlPath: MaybeRefOrGetter<string>): Ref<string> => {
  const whlDomainRef = useWhlDomain();

  return computed(() => {
    const baseUrl = toValue(whlDomainRef);
    const urlPathValue = toValue(urlPath);

    if (baseUrl.endsWith('/') && urlPathValue.startsWith('/')) {
      // if baseUrl ends with a slash and urlPathValue starts with a slash, remove one of them
      return `https://${baseUrl}${urlPathValue.slice(1)}`;
    }

    return `https://${baseUrl}${urlPathValue}`;
  });
};
